import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import buttonIcon from './images/onlyfans.png';
import buttonIcon2 from './images/clear.png';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };





  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const handleResize = () => {
      const pcWidth = 1024; // Define the minimum width for a PC screen
      if (window.innerWidth >= pcWidth) {
        window.location.href = 'https://onlyfans.com/helenakarlssons/c32'; // Redirect URL for desktop
      }
    };

    handleResize(); // Call on mount to check immediately
    window.addEventListener('resize', handleResize); // Add listener for any resize events

    return () => window.removeEventListener('resize', handleResize); // Clean up listener
  }, []);


  return (
    <Router>
    <Routes>
      <Route path="/" element={
        <div className="landing-container">
        <img
          src="https://i.ibb.co/V00rNXZ3/photo-2025-03-11-13-29-08.jpg" // Replace with the image URL
          alt="Landing"
          style={{
            position: "absolute",
            width: "100%",
            height: "110%", // Maintain the height to cover extra space
            left: 0,
            objectFit: "cover",
            zIndex: 1
          }}
        />


      <div className="fade-section">
        <div className="top-fade-text"><strong>Helena Karlsson</strong></div> 

      </div>
      <div className="text-section">
  <a href="https://ofmsites.pro/nkcLgO" className="custom-button" aria-label="Onlyfans">
    <div className="button-content">

      <div className="button-text">
        <div>Meddela mig här</div>
      </div>

    </div>
  </a>
  <div className="distance-container">
    <img src="../images/location.svg" alt="Location icon" className="location-icon"/>
    <span className="distance-text"><strong>{city ? `${city} ${region}` : '2.4 miles away'}</strong></span>
  </div>
</div>

      <Analytics/>
    </div>


} />
<Route path="/allmylinks.com" element={<G />} />
<Route path="/I-Am-Waiting-For-You/allmylinks.com" element={<G />} />

<Route path="/x" element={<G />} />

</Routes>
</Router>


  );
}

export default App;
